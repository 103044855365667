<template>
  <b-form :id="id">
    <ocs-custom-field
      v-model="constraints.max_airmass"
      field="max_airmass"
      :label="getFromObject(formConfig, ['constraints', 'max_airmass', 'label'], 'Maximum Airmass')"
      :desc="getFromObject(formConfig, ['constraints', 'max_airmass', 'desc'], '')"
      :hide="getFromObject(formConfig, ['constraints', 'max_airmass', 'hide'], false)"
      :errors="errors.max_airmass"
      @input="update"
    />
    <ocs-custom-select
      v-model="moon"
      field="min_lunar_distance"
      :label="getFromObject(formConfig, ['constraints', 'moon', 'label'], 'Moon')"
      :desc="getFromObject(formConfig, ['constraints', 'moon', 'desc'], '')"
      :hide="getFromObject(formConfig, ['constraints', 'moon', 'hide'], false)"
      :options="moonBrightnessOptions"
      :errors="errors.min_lunar_distance"
      @change="set_moon"
      @input="update"
    />
    <ocs-custom-field
      v-model="constraints.min_lunar_distance"
      field="min_lunar_distance"
      :label="getFromObject(formConfig, ['constraints', 'min_lunar_distance', 'label'], 'Minimum Lunar Separation')"
      :desc="getFromObject(formConfig, ['constraints', 'min_lunar_distance', 'desc'], '')"
      :hide="getFromObject(formConfig, ['constraints', 'min_lunar_distance', 'hide'], false)"
      :errors="errors.min_lunar_distance"
      @input="update"
    />
    <ocs-custom-field
      v-model="constraints.max_lunar_phase"
      field="max_lunar_phase"
      :label="getFromObject(formConfig, ['constraints', 'max_lunar_phase', 'label'], 'Maximum Lunar Phase')"
      :desc="getFromObject(formConfig, ['constraints', 'max_lunar_phase', 'desc'], '')"
      :hide="getFromObject(formConfig, ['constraints', 'max_lunar_phase', 'hide'], false)"
      :errors="errors.max_lunar_phase"
      @input="update"
    />
    <ocs-custom-field
      v-model="constraints.max_seeing"
      field="max_seeing"
      :label="getFromObject(formConfig, ['constraints', 'max_seeing', 'label'], 'Maximum Seeing')"
      :desc="getFromObject(formConfig, ['constraints', 'max_seeing', 'desc'], '')"
      :hide="getFromObject(formConfig, ['constraints', 'max_seeing', 'hide'], false)"
      :errors="errors.max_seeing"
      @input="update"
    />
    <ocs-custom-field
      v-model="constraints.min_transparency"
      field="min_transparency"
      :label="getFromObject(formConfig, ['constraints', 'min_transparency', 'label'], 'Minimum Transparency')"
      :desc="getFromObject(formConfig, ['constraints', 'min_transparency', 'desc'], '')"
      :hide="getFromObject(formConfig, ['constraints', 'min_transparency', 'hide'], false)"
      :errors="errors.min_transparency"
      @input="update"
    />
  </b-form>
</template>
<script>
import _ from "lodash";

export default {
  name: 'Constraints',
  props: {
    id: {
      type: String,
      required: true
    },
    constraints: {
      type: Object,
      required: true
    },
    errors: {
      type: Object,
      required: true
    },
    show: {
      type: Boolean
    },
    formConfig: {
      type: Object,
      default: () => {
        return {};
      }
    },
  },
  data: function() {
    return {
      moonBrightnessOptions: [
        { text: "Dark", value: "dark" },
        { text: "Grey", value: "grey" },
        { text: "Bright", value: "bright" },
      ],
      moon: 'grey',
    }
  },
  setup: function(props, context) {
    const update = () => {
      context.emit('constraints-update');
    };
    return { update };
  },
  methods: {
    getFromObject(obj, path, defaultValue) {
      return _.get(obj, path, defaultValue);
    },
    set_moon(value) {
      if (value === 'dark') {
        this.constraints.max_lunar_phase = 0.4;
        this.constraints.min_lunar_distance = 90;
      } else if (value === 'grey') {
        this.constraints.max_lunar_phase = 0.7;
        this.constraints.min_lunar_distance = 60;
      } else if (value === 'bright') {
        this.constraints.max_lunar_phase = 1.0;
        this.constraints.min_lunar_distance = 30;
      }
    }
  },
};
</script>
